<template>
  <!-- navbar -->
  <div class="shadow" v-show="showDrapDown"></div>
  <div :class="['home-nav', scrollTop != 0 ? 'gray' : '']" id="getheight">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <div class="nav-logo">
              <div class="img"></div>
              <p class="title">青岛元罡科技有限公司</p>
            </div>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="clickToggler()"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 narbarDropDown">
              <li class="nav-item" @click="toLocation('F1')">
                <a
                  :class="[navIndex == 1 ? 'active' : '', 'nav-link']"
                  aria-current="page"
                >
                  首页
                  <div class="acrrow"></div>
                </a>
              </li>
              <li class="nav-item" @click="toLocation('F2')">
                <a
                  :class="[navIndex == 2 ? 'active' : '', 'nav-link']"
                  aria-current="page"
                  >公司简介
                  <div class="acrrow"></div
                ></a>
              </li>
              <li class="nav-item" @click="toLocation('F3')">
                <a
                  :class="[navIndex == 3 ? 'active' : '', 'nav-link']"
                  aria-current="page"
                  >业务范围
                  <div class="acrrow"></div
                ></a>
              </li>
              <li class="nav-item" @click="toLocation('F4')">
                <a
                  :class="[navIndex == 4 ? 'active' : '', 'nav-link']"
                  aria-current="page"
                  >服务流程
                  <div class="acrrow"></div
                ></a>
              </li>
              <li class="nav-item" @click="toLocation('F5')">
                <a
                  :class="[navIndex == 5 ? 'active' : '', 'nav-link']"
                  aria-current="page"
                  >公司业绩
                  <div class="acrrow"></div
                ></a>
              </li>
              <li class="nav-item" @click="toLocation('F6')">
                <a
                  :class="[navIndex == 6 ? 'active' : '', 'nav-link']"
                  aria-current="page"
                  >应用案例
                  <div class="acrrow"></div
                ></a>
              </li>
              <li class="nav-item" @click="toLocation('F7')">
                <a
                  :class="[navIndex == 7 ? 'active' : '', 'nav-link']"
                  aria-current="page"
                  >联系我们
                  <div class="acrrow"></div
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- 轮播图 -->
  <div
    id="carouselExampleIndicators"
    class="carousel slide home-carousel"
    data-bs-ride="carousel"
    @touchstart="getFlag()"
    @touchmove="setFlag()"
    @touchend="change()"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active point"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="1"
        aria-label="Slide 2"
        class="point"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="2"
        aria-label="Slide 3"
        class="point"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active carousel-item1">
        <img
          src="../assets/home/bannerOne1.png"
          class="d-block w-100"
          alt="..."
          v-if="screenWidth > 576"
        />
        <img
          src="../assets/home/bannerOne2.png"
          class="d-block w-100"
          alt="..."
          v-else
        />
      </div>
      <div class="carousel-item carousel-item2">
        <img
          src="../assets/home/bannerTwo1.png"
          class="d-block w-100"
          alt="..."
          v-if="screenWidth > 576"
        />
        <img
          src="../assets/home/bannerTwo2.png"
          class="d-block w-100"
          alt="..."
          v-else
        />
      </div>
      <div class="carousel-item carousel-item2">
        <img
          src="../assets/home/bannerThree1.png"
          class="d-block w-100"
          alt="..."
          v-if="screenWidth > 576"
        />
        <img
          src="../assets/home/bannerThree2.png"
          class="d-block w-100"
          alt="..."
          v-else
        />
      </div>
    </div>
  </div>
  <!-- 公司简介 -->
  <div class="container introduction" id="F2">
    <div class="title">
      <div class="main">公司简介</div>
      <div class="english">COMPANY PROFILE</div>
    </div>
    <div class="content">
      <div class="image">
        <div class="bg" v-show="screenWidth > 768"></div>
        <div class="img">
          <img
            src="../assets/home/tu.png"
            class="d-block w-100"
            alt="..."
            v-if="screenWidth > 768"
          />
          <img
            src="../assets/home/tu2.png"
            class="d-block w-100"
            alt="..."
            v-else
          />
        </div>
      </div>
      <div class="text">
        <div class="wrap">
          <p>
            青岛元罡科技是一家专门从事互联网平台开发、软件系统开发、服务类机器人定制、软硬件集成服务、企业信息化流程改造和信息技术支持的高科技公司。公司拥有20余项著作权专利，在智慧政务、智慧园区、智慧场馆、智慧楼宇、电商平台等应用领域已形成标准化产品和成熟的解决方案。
          </p>
          <p>
            目前，公司已应用到各领域的产品有：退役军人服务系统，智慧残联服务平台、共享展具平台、无感认证系统、远程招聘系统、仓储管理系统、租售服务一体化平台、全过程工程项目管理平台、立体停车智能服务系统、智慧订餐系统、服务类智能机器人等。
          </p>
          <p>
            元罡自主研发的“元罡云平台”，立足去中心化，着眼管理、服务、供需匹配、生态交互，为政府、行业、各产业及中小微企业建设分布式平台系统，为G端、B端赋能，C端裂变。
            公司秉承“专业、专注、专心”的理念，共同打造网格互联网、价值互联网应用平台，实现共创、共建、共享、共发展。
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- 业务范围 -->
  <div class="business" id="F3">
    <div class="container">
      <div class="title">
        <div class="main">业务范围</div>
        <div class="english">SCOPE OF BUSINESS</div>
      </div>
      <div class="content row">
        <div class="bigItem col-lg-4 col-md-6 col-12">
          <div class="wrap">
            <div class="top">
              <div class="icon icon1"></div>
              <div class="text1">互联网平台开发</div>
            </div>
            <div class="bottom">
              <div class="package row">
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">智慧政务</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">智慧园区</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">智慧场馆</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">智慧楼宇</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">企业信息化流程改造</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bigItem col-lg-4 col-md-6 col-12 bigItem2">
          <div class="wrap">
            <div class="top">
              <div class="icon icon2"></div>
              <div class="text1">软件系统定制开发</div>
            </div>
            <div class="bottom">
              <div class="package row">
                <div class="itemWrap col-6">
                  <div class="item">ERP系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">电商系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">物资管理系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">仓储管理系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">采购管理系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">订单管理系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">评价管理系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">流程审批管理系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">班组建设管理系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">餐厅订餐管理系统</div>
                </div>
                <div class="itemWrap col-6">
                  <div class="item">远程招聘管理系统</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bigItem col-lg-4 col-md-6 col-12">
          <div class="wrap">
            <div class="top">
              <div class="icon icon3"></div>
              <div class="text1">微信开发</div>
            </div>
            <div class="bottom">
              <div class="package row">
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">微信公众号定制开发</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">微信服务号定制开发</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">微官网</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">微商场</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">微论坛</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bigItem col-lg-4 col-md-6 col-12">
          <div class="wrap">
            <div class="top">
              <div class="icon icon4"></div>
              <div class="text1">小程序开发</div>
            </div>
            <div class="bottom">
              <div class="package row">
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">服务类小程序</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">商城类小程序</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">展示类小程序</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">缴费类小程序</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">各行业小程序定制开发</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bigItem col-lg-4 col-md-6 col-12 bigItem5">
          <div class="wrap">
            <div class="top">
              <div class="icon icon5"></div>
              <div class="text1">服务类机器人定制</div>
            </div>
            <div class="bottom">
              <div class="package row">
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">服务类机器人定制</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">迎宾类机器人</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">安防类机器人</div>
                </div>
                <div class="itemWrap col-md-12 col-sm-6 col-6">
                  <div class="item">酒店类机器人</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bigItem col-lg-4 col-md-6 col-12 bigItem6">
          <div class="wrap">
            <div class="lastWrap">
              <div class="lastIcon"></div>
              <div class="lastText">各类APP开发</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 服务流程 -->
  <div class="container flow" id="F4">
    <div class="title">
      <div class="main">服务流程</div>
      <div class="english">SERVICE PROCESS</div>
    </div>
    <div class="content">
      <img
        src="../assets/home/fuwuliucheng.png"
        class="d-block w-100"
        alt="..."
        v-if="screenWidth > 768"
      />
      <img
        src="../assets/home/fuwuliucheng2.png"
        class="d-block w-100"
        alt="..."
        v-else
      />
    </div>
  </div>
  <!-- 公司业绩 -->
  <div class="performance" id="F5">
    <div class="container">
      <div class="title">
        <div class="main">公司业绩</div>
        <div class="english">CORPORATE PERFORMANCE</div>
      </div>
      <div class="content">
        <img
          src="../assets/home/yeji.png"
          class="d-block w-100"
          alt="..."
          v-if="screenWidth > 768"
        />
        <img
          src="../assets/home/yeji2.png"
          class="d-block w-100"
          alt="..."
          v-else
        />
      </div>
    </div>
  </div>
  <!-- 应用案例 -->
  <div class="container case" id="F6">
    <div class="title">
      <div class="main">应用案例</div>
      <div class="english">APPLICATION CASES</div>
    </div>
    <div class="contentPc" v-if="screenWidth > 576">
      <div class="bg">
        <!-- <img src="../assets/home/case.png" class="d-block w-100" alt="..." /> -->
        <div
          class="item item1"
          data-bs-toggle="modal"
          data-bs-target="#Veterans"
        ></div>
        <div
          class="item item2"
          data-bs-toggle="modal"
          data-bs-target="#equipment"
        ></div>
        <div
          class="item item3"
          data-bs-toggle="modal"
          data-bs-target="#campus"
        ></div>
        <div
          class="item item4"
          data-bs-toggle="modal"
          data-bs-target="#federation"
        ></div>
        <div
          class="item item5"
          data-bs-toggle="modal"
          data-bs-target="#parking"
        ></div>
        <div
          class="item item6"
          data-bs-toggle="modal"
          data-bs-target="#process"
        ></div>
        <div
          class="item item7"
          data-bs-toggle="modal"
          data-bs-target="#nofeeling"
        ></div>
        <div
          class="item item8"
          data-bs-toggle="modal"
          data-bs-target="#rental"
        ></div>
        <div
          class="item item9"
          data-bs-toggle="modal"
          data-bs-target="#robot"
        ></div>
        <div
          class="item item10"
          data-bs-toggle="modal"
          data-bs-target="#warehouse"
        ></div>
        <div
          class="item item11"
          data-bs-toggle="modal"
          data-bs-target="#recruit"
        ></div>
        <div
          class="item item12"
          data-bs-toggle="modal"
          data-bs-target="#ordering"
        ></div>
        <div class="centre">
          <div class="icon"></div>
          <div class="text1">元罡云平台</div>
        </div>
        <div class="bottom">
          <div class="text1">计算、存储、网络、大数据中心</div>
          <div class="english">
            <div>ERP on Cloud</div>
            <div>CAD、CAE、PLM、MES on Cloud</div>
          </div>
        </div>
      </div>
    </div>
    <div class="contentMobile" v-else>
      <div class="wrap">
        <div class="top">
          <div class="icon"></div>
          <div class="text1">元罡云平台</div>
        </div>
        <div class="bottom">
          <div class="item" data-bs-toggle="modal" data-bs-target="#Veterans">
            退役军人服务平台
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#mnofeeling">
            无感认证系统
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#equipment">
            共享展具平台
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#warehouse">
            仓储管理系统
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#federation">
            智慧残联服务平台
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#recruit">
            线上招聘系统
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#campus">
            智慧校园数字管理系统
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#ordering">
            智慧订餐系统
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#parking">
            立体停车智能服务系统
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#robot">
            服务类智能机器人
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#mprocess">
            全过程工程项目管理平台
            <div class="acrrow"></div>
          </div>
          <div class="item" data-bs-toggle="modal" data-bs-target="#rental">
            租售服务一体化平台
            <div class="acrrow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 联系我们 -->
  <div class="relation" id="F7">
    <div class="container">
      <div class="title">
        <div class="main">联系我们</div>
        <div class="english">CORE SERVICES</div>
      </div>
      <div class="content" @click="toMap">
        <img
          src="../assets/home/map.png"
          class="d-block w-100"
          alt="..."
          v-if="screenWidth > 768"
        />
        <img
          src="../assets/home/map2.png"
          class="d-block w-100"
          alt="..."
          v-else
        />
      </div>
    </div>
  </div>
  <!-- 底部栏 -->
  <div class="home-bottom">
    <div class="container">
      <div class="msg">
        <div class="block" v-if="screenWidth > 992">
          <div class="item">
            <div class="icon icon1"></div>
            <div class="text1">公司名称：青岛元罡科技有限公司</div>
          </div>
          <div class="item">
            <div class="icon icon2"></div>
            <div class="text1">E-mail：yuangang_keji@163.com</div>
          </div>
        </div>
        <div class="block" v-if="screenWidth > 992">
          <div class="item">
            <div class="icon icon3"></div>
            <div class="text1">联系电话：0532-82030338</div>
          </div>
          <div class="item">
            <div class="icon icon4"></div>
            <div class="text1">
              公司地址：山东省青岛市崂山区山东头路58号 盛和大厦2号楼13F
            </div>
          </div>
        </div>
        <div class="moblieBlock" v-else>
          <div class="line">公司名称：青岛元罡科技有限公司</div>
          <div class="line">E-mail：yuangang_keji@163.com</div>
          <div class="line">联系电话：0532-82030338</div>
          <div class="line">
            公司地址：山东省青岛市崂山区山东头路58号 盛和大厦2号楼13F
          </div>
        </div>
        <div class="QR">
          <div class="text1">微信公众号</div>
          <img src="../assets/home/erweima.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="copyright" @click="Jump()">
    版权所有©青岛元罡科技有限公司备案号：鲁ICP备2021001456号-4 | Qingdao
    Yuangang Technology Co., Ltd
  </div>
  <!-- 回到顶部 -->
  <div
    class="backOrigin"
    @click="toLocationBack()"
    v-show="showTop"
    v-if="screenWidth > 768"
  >
    <div class="icon"></div>
    <div class="text1">TOP</div>
  </div>
  <div
    class="backOriginM"
    @click="toLocationBack()"
    v-show="showTop"
    v-else
  ></div>
  <modal
    class="modal fade"
    id="Veterans"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 退役军人服务系统 </template>
    <template v-slot:down>
      <div class="Veterans">
        <div class="Veterans-top">
          <p class="text">
            退役军人服务系统，主要是运用互联网、大数据等技术手段，打通退役军人各级服务中心与退役军人之间的信息壁垒，并建立高效的信息直通机制，充分体现了
            “ 互联网 + 政务服务 ” 的先进理念，全面提升了退役军人的服务水平。
          </p>
          <p class="text">
            系统通过1+3+N系统模式为退役军人提供信息化保障。平台数据传输实行全过程加密装置，并于服务器端安装加密，确保了数据和平台安全，此综合加密技术为“元罡云平台”首创。
          </p>
        </div>
        <div class="Veterans-middle">
          <ul>
            <li>1个平台</li>
            <li>3个应用端</li>
            <li>N个功能</li>
          </ul>
        </div>
        <div class="Veterans-bottom">
          <ul>
            <li v-for="it in items" :key="it">{{ it }}</li>
          </ul>
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="equipment"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 共享展具平台 </template>
    <template v-slot:down>
      <div class="equipment">
        <div class="equipment-top">
          <p class="text">
            共享展具平台是集模块化、环保型展具租赁、销售、设计定制、物流配送、安装服务于一体的供应链平台，便于环保型展具的应用推广，降低闲置展具的使用率，解决获客难、沟通成本高、价格不透明、产品质量无保障、服务效能低等问题。
          </p>
          <p class="text">
            共享展具平台让更多人能透过较低门槛进入市场，为市场引入更多元化的产品和服务。同时，尽可能的提供推荐选择，实现了资源的合理利用，消费者能因此在传统经济模式以外享有更多的选择。
          </p>
        </div>
        <div class="equipment-middle">
          <img src="../assets/modal/tu.png" alt="" />
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="federation"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 智慧残联服务平台 </template>
    <template v-slot:down>
      <div class="equipment federation">
        <div class="equipment-top">
          <p class="text">
            智慧残联服务平台综合运用云计算技术、信息无障碍技术，融合互联网+政务的服务理念，“一个平台，五个客户端，七项服务，N项功能”的系统模式，全面助力残联、残疾人与社会服务单位之间的互联互通，进一步推进残联各项业务全面实现线上办理。
          </p>
        </div>
        <div class="equipment-middle">
          <img src="../assets/modal/tu1.png" alt="" />
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="campus"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 智慧校园数字管理系统 </template>
    <template v-slot:down>
      <div class="campus equipment">
        <div class="campus-top">
          <p class="text">
            智慧校园管理系统是数字化智慧校园建设不断发展的产物，是教育信息化改革的部分体现。包含基本功能外，还综合考虑教改及学校各方面的需求，加入了更多的实用功能，实现无纸化办公。比如：显示班级及教室基础信息、当前日期和时间、以及由传感器采集的实时数据，同时显示多端平台数据同步，
            具有多样性的交互功能。
          </p>
        </div>
        <div class="campus-middle">
          <ul>
            <li>
              <div>01 信息可视化</div>
              <p>
                通过安装多功能电子班牌、传达室多功能一体机，打通并连接校园所有显示屏、将学生刷卡、人脸识别、二维码识别、智能语音等技术嵌入，实现校园数据"采集、显示、交互”智能化，构建智慧校园软硬件结合的整体环境。
              </p>
            </li>
            <li>
              <div>02 沟通平台化</div>
              <p>
                关注微信公众号，可完成校级信息向家长端推送，家长阅读通知回执，投票、评价、通知等实时显示，解决学校与家长之间的信息沟通问题，实现家校互通。
              </p>
            </li>
            <li>
              <div>03 管理移动化</div>
              <p style="margin-bottom: 0.625rem">
                将学校内部管理，全面向移动端移植，校长和教师在手机公众号上，就可以完成日常的教务和班级管理工作。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="parking"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 立体停车智能服务系统 </template>
    <template v-slot:down>
      <div class="parking">
        <div class="parking-top">
          <p class="text">
            智能立体停车系统对车位进行动态检测，显示屏展示最近距离空车位信息，车辆自动进行立体移动到指定车位，提升了空间利用率，更多的车位和更快的存取速度提高了停车场的收入，更降低了维护成本。实现全过程智能化、无人化、高效化。
          </p>
          <ul>
            <li>
              <div>1</div>
              <p>系统运算迅速、时效性强</p>
            </li>
            <li>
              <div>2</div>
              <p>可延展性高、极具灵活性</p>
            </li>
            <li>
              <div>3</div>
              <p>系统组网简洁、稳定性高</p>
            </li>
          </ul>
          <p class="text">
            智能立体停车系统中的集数据采集、信息计算、余位显示、自动移车功能为一体，高度智能化，有效避免组件过多带来的数据丢失问题，使系统组网更简单，稳定性更高。
          </p>
        </div>
        <div class="parking-middle">
          <img src="../assets/modal/1.png" alt="" />
          <img src="../assets/modal/2.png" alt="" />
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="process"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 全过程工程项目管理平台 </template>
    <template v-slot:down>
      <div class="process">
        <div class="process-top">
          <p class="text">
            全过程工程项目管理平台，以对工程项目信息的归集、整理与沉淀，实现从前期意向、立项、计划、实施、竣工、总结全流程可视化智能管控。系统由管理端工作台+移动APP、数据大屏、用户端四部分组成，适用于多场景项目全过程管理，具有很强的可复制性。
          </p>
        </div>
        <div class="process-middle">
          <div class="process-box">
            <h5 class="p-head">系统特点</h5>
            <ul>
              <li>01 该系统实时把控项目投资进度，跟踪项目施工进程</li>
              <li>02 引导人员合规操作，及时排除安全隐患</li>
              <li>03 精准调配任务，合理优化资源</li>
              <li>04 在全面管控工程项目的同时，利用数据驱动决策</li>
            </ul>
          </div>
          <div class="process-box">
            <h5 class="p-head">流程</h5>
            <div class="procedure">
              <div>
                <img src="../assets/modal/yixiang.png" alt="" />
                <p>前期意向</p>
              </div>
              <img src="../assets/modal/jiantou.png" alt="" />
              <div>
                <img src="../assets/modal/lixiang.png" alt="" />
                <p>立项</p>
              </div>
              <img src="../assets/modal/jiantou.png" alt="" />
              <div>
                <img src="../assets/modal/jihua.png" alt="" />
                <p>计划</p>
              </div>
              <img src="../assets/modal/jiantou.png" alt="" />
              <div>
                <img src="../assets/modal/shishi.png" alt="" />
                <p>实施</p>
              </div>
              <img src="../assets/modal/jiantou.png" alt="" />
              <div>
                <img src="../assets/modal/jungong.png" alt="" />
                <p>竣工</p>
              </div>
              <img src="../assets/modal/jiantou.png" alt="" />
              <div>
                <img src="../assets/modal/zongjie.png" alt="" />
                <p>总结</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="nofeeling"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 无感认证系统 </template>
    <template v-slot:down>
      <div class="nofeeling">
        <div class="nofeeling-top">
          <p class="text">
            无感认证实现了线上人员无需操作的监管模式，根据综合的大数据分析，动态分析相关人员的行为轨迹并进行信息采集，精准定位到个人，真正实现“让数据多跑路，群众少跑腿”，使服务更高效、便捷、人性化，同时减少了工作人员的负担，对涉及的数据及资金提供了有效的保障。
          </p>
          <p class="text">
            随时接入各部门的数据系统，帮助解决需要的数据分析，利用现代化大数据手段，对相应的人群进行真正的“免打扰认证”，更可以结合现代物联网，实现园区及设备的智能预警，提高信息采集和应用的效率，减少资源浪费，真正解决各部门核心痛点问题。
          </p>
        </div>
        <div class="nofeeling-middle">
          <div class="nofeeling-box">
            <div class="master">数据来源</div>
            <div>政府权威部门数据</div>
            <span>+</span>
            <div>用户行为</div>
            <span>+</span>
            <div>···</div>
          </div>
          <div class="nofeeling-box">
            <div class="master">机器学习</div>
            <div>数据积累</div>
            <img src="../assets/modal/jiantou1.png" alt="" />
            <div>数据刷新</div>
            <img src="../assets/modal/jiantou1.png" alt="" />
            <div>真实模拟</div>
            <img src="../assets/modal/jiantou1.png" alt="" />
            <div>判断异常</div>
            <img src="../assets/modal/jiantou1.png" alt="" />
            <div>···</div>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="rental"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 租售服务一体化平台 </template>
    <template v-slot:down>
      <div class="rental">
        <div class="rental-top">
          <p class="text">
            租售服务一体化平台是集商品租赁、销售、物流配送、售后服务于一体的供应链管理系统，是电商平台的进一步优化，在拥有电商功能的同时加入商品租赁以及商品售后服务，如设备的安装、移机，家具的拼装等，有效解决了售前与售后系统管理断层的问题。
          </p>
        </div>
        <div class="rental-middle">
          <div>平台优势</div>
          <ul>
            <li><span>01</span>多服务商共驻平台，形成产业生态</li>
            <li><span>02</span>商品订单、服务工单双层流转，实时管控订单进程</li>
            <li><span>03</span>租赁、销售库存同步，仓库进销存全面管控</li>
            <li><span>04</span>服务任务智能派单，售后服务责任到人，一票到底</li>
            <li>
              <span>05</span
              >款项分次支付，商品过关服务满意，用户支付尾款，遏制商家恶性交易，提高用户交易体验
            </li>
          </ul>
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="robot"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 服务类智能机器人 </template>
    <template v-slot:down>
      <div class="robot">
        <div class="robot-top">
          <p class="text" style="margin-bottom: 0">
            智能机器人为政企、教育、金融、医疗、餐饮酒店等行业提供多项功能服务，与各行业创新融合，聚焦客户需求赋能行业应用，并且支持产品、外形定制，满足不同用户不同使用场景的需求，聚合产业新生态。
          </p>
          <p class="text">
            采用27寸十点触摸全角高清大屏，适用各类应用场景，实现沉浸式客户体验，基于自主研发的AI引擎，配备4G模块，随时随地感知用户的喜怒哀乐。
          </p>
        </div>
        <div class="robot-middle">
          <h3>硬件配置优势</h3>
          <div class="robot-flex">
            <img src="../assets/modal/robot.png" alt="" />
            <div class="robot-box">
              <h4>— 软件配置优势 —</h4>
              <div><hr /></div>
              <p>语音识别与全球领先的科大讯飞深度合作，支持多种语种及方言</p>
              <div><hr /></div>
              <p>同时搭载四麦阵列,能够实现360°全向拾音</p>
              <div><hr /></div>
              <p>智能机器人精准定位、追踪声源、并且支持全双工</p>
              <div><hr /></div>
              <p>
                集成了高清高亮摄像头、激光雷达、超声波避障等多种类型的 传感器
              </p>
              <div><hr /></div>
              <p>
                领先的算法处理传感器收集的多维度信息，共同为企业数字化、
                智能化升级提升助力
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="warehouse"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 仓储管理系统 </template>
    <template v-slot:down>
      <div class="equipment">
        <div class="equipment-top">
          <p class="text">
            仓储管理系统适用于多种销售模式，是一款既能满足线下零售、批发业务又能满足线上网店直售、分销业务的进销存货品管理系统。帮助企业快速实现采购入库、多平台订单处理、销售发货、商品实时库存、缺货预警、仓库管理、库位管理、库内盘货、库内移位、仓库调拨、财务往来对账、运营数据分析等业务管理。
          </p>
        </div>
        <div class="warehouse-middle">
          <img class="ware" src="../assets/modal/warehouse.png" alt="" />
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="recruit"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 线上招聘系统 </template>
    <template v-slot:down>
      <div class="recruit">
        <div class="recruit-top">
          <p class="text" style="margin-bottom: 0">
            线上招聘系统具备招聘投递、简历筛选、视频面试、招聘结果公布等功能，面试者与面试官进行线上一对一或多对一的音视频通话，提供了高质量、高并发、超低延时的实时互动解决方案，并有效保障了视频面试过程中稳定、流畅的互动体验，助力政务及企业在疫情防控期间高效率招聘就业。
          </p>
          <p class="text">
            由人社局、人才市场以及各大高校组织举办的线上招聘活动，将传统线下招聘形式复制到线上。通过在线面试、线上指导、云端签约等活动形式，为招聘企业们和求职者提供交流对接平台。在线上招聘会上，每家用人单位都以“虚拟招聘间”形式呈现，求职者可以对心仪的企业和岗位进行简历投递，并通过视频面试的方式直接“面对面交流”进一步沟通洽谈。双方可以快速达成有效性沟通，实现人才的精准匹配。面试全程录制方便HR回顾评判。相比传统面试，可安排面试时间更广泛，面试效率更高，方便人才筛选，降低单个人员面试成本，提高效益。
          </p>
        </div>
        <div class="recruit-middle">
          <img src="../assets/modal/tu2.png" alt="" />
          <div>
            <h3>系统优势：</h3>
            <ul>
              <li>01 为招聘企业和求职者提供在线交流平台</li>
              <li>02 面试时间范围更广，效率更高</li>
              <li>03 实现人才精准匹配</li>
              <li>04 降低单个人员面试成本，提高效益</li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="ordering"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 智慧订餐系统 </template>
    <template v-slot:down>
      <div class="ordering">
        <div class="ordering-top">
          <p class="text">
            智慧订餐系统是基于政府机关、企事业单位、学校等大型单位食堂以及楼宇餐厅的管理模式，创新打造的餐厅预订餐系统。该系统不仅包含用户管理、消费限制、菜品管理等基本订餐功能，还具有餐厅整体管控能力，包括餐厅财务管理、数据统计、互动管理、食材管理、食品安全追溯等，在提升餐厅就餐满意度的同时，满足管理方对于餐厅运营全面管控的需求。
          </p>
        </div>
        <div class="ordering-middle">
          <img class="ware" src="../assets/modal/tu3.png" alt="" />
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="mprocess"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 全过程工程项目管理平台 </template>
    <template v-slot:down>
      <div class="mprocess">
        <div class="mprocess-top">
          <p class="text">
            全过程工程项目管理平台，以对工程项目信息的归集、整理与沉淀，实现从前期意向、立项、计划、实施、竣工、总结全流程可视化智能管控。系统由管理端工作台+移动APP、数据大屏、用户端四部分组成，适用于多场景项目全过程管理，具有很强的可复制性。
          </p>
        </div>
        <div class="mprocess-middle">
          <div class="mprocess-box">
            <h5 class="p-head">系统特点</h5>
            <ul
              class="
                row
                row-cols-1
                row-cols-sm-2
                row-cols-md-2
                row-cols-lg-2
                row-cols-xl-2
                row-cols-xxl-2
              "
            >
              <li class="col-2">
                01 该系统实时把控项目投资进度，跟踪项目施工进程
              </li>
              <li class="col-2">02 引导人员合规操作，及时排除安全隐患</li>
              <li class="col-2">03 精准调配任务，合理优化资源</li>
              <li class="col-2">
                04 在全面管控工程项目的同时，利用数据驱动决策
              </li>
            </ul>
          </div>
          <div class="mprocess-box">
            <h5 class="p-head">流程</h5>
            <div class="procedure">
              <div>
                <div class="items">
                  <div>
                    <img src="../assets/modal/yixiang.png" alt="" />
                    <p>前期意向</p>
                  </div>
                  <img src="../assets/modal/jiantou.png" alt="" />
                  <div>
                    <img src="../assets/modal/lixiang.png" alt="" />
                    <p>立项</p>
                  </div>
                  <img src="../assets/modal/jiantou.png" alt="" />
                  <div>
                    <img src="../assets/modal/jihua.png" alt="" />
                    <p>计划</p>
                  </div>
                </div>
                <div class="items">
                  <div>
                    <img src="../assets/modal/shishi.png" alt="" />
                    <p>实施</p>
                  </div>
                  <img src="../assets/modal/jiantou.png" alt="" />
                  <div>
                    <img src="../assets/modal/jungong.png" alt="" />
                    <p>竣工</p>
                  </div>
                  <img src="../assets/modal/jiantou.png" alt="" />
                  <div>
                    <img src="../assets/modal/zongjie.png" alt="" />
                    <p>总结</p>
                  </div>
                </div>
              </div>
              <img src="../assets/modal/jiantou2.png" class="arrow" alt="" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
  <modal
    class="modal fade"
    id="mnofeeling"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <template v-slot:up> 无感认证系统 </template>
    <template v-slot:down>
      <div class="mnofeeling">
        <div class="mnofeeling-top">
          <p class="text" style="margin-bottom: 0">
            无感认证实现了线上人员无需操作的监管模式，根据综合的大数据分析，动态分析相关人员的行为轨迹并进行信息采集，精准定位到个人，真正实现“让数据多跑路，群众少跑腿”，使服务更高效、便捷、人性化，同时减少了工作人员的负担，对涉及的数据及资金提供了有效的保障。
          </p>
          <p class="text">
            随时接入各部门的数据系统，帮助解决需要的数据分析，利用现代化大数据手段，对相应的人群进行真正的“免打扰认证”，更可以结合现代物联网，实现园区及设备的智能预警，提高信息采集和应用的效率，减少资源浪费，真正解决各部门核心痛点问题。
          </p>
        </div>
        <div class="mnofeeling-middle">
          <div class="mnofeeling-box flex-box">
            <div class="master items">数据来源</div>
            <div class="items">政府权威部门数据</div>
            <span>+</span>
            <div class="items">用户行为</div>
            <span>+</span>
            <div class="items">···</div>
          </div>
          <div class="mnofeeling-box bl-border">
            <div class="master items">机器学习</div>
            <div class="items">数据积累</div>
            <img src="../assets/modal/jiantou1.png" alt="" />
            <div class="items">数据刷新</div>
            <img src="../assets/modal/jiantou1.png" alt="" />
            <div class="items">真实模拟</div>
            <img src="../assets/modal/xiangxia.png" class="down-play" alt="" />
            <div class="items-box">
              <div class="items">···</div>
              <img
                src="../assets/modal/xiangzuo.png"
                class="left-play"
                alt=""
              />
              <div class="items">判断异常</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "@/components/html/modal";
export default {
  components: { modal },
  data() {
    return {
      navIndex: 1,
      screenWidth: document.body.clientWidth,
      flagX: 0,
      flagXM: 0,
      items: [
        "时政资讯",
        "政策法规",
        "服务清单",
        "信息管理",
        "志愿服务",
        "无感认证",
        "荣军联盟",
        "兵职场",
        "公益岗位",
        "线上年检",
        "体检报名",
        "数据分析",
      ],
      showTop: false,
      valHeight: [],
      scrollTop: 0,
      showDrapDown: false,
      nav: 0,
    };
  },
  created() {},
  mounted() {
    const that = this;
    window.addEventListener("resize", function () {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
        that.getValHeight();
      })();
    });
    window.addEventListener("scroll", this.btn_pos);
    setTimeout(() => {
      this.getValHeight();
    }, 100);
  },
  methods: {
    Jump() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
    clickToggler() {
      this.showDrapDown = !this.showDrapDown;
    },
    getValHeight() {
      let navHeight = document.querySelector("#getheight").scrollHeight;
      let ulHeight = document.querySelector(
        "#navbarSupportedContent"
      ).offsetHeight;
      let nav;
      if (this.screenWidth > 992 - 15) {
        nav = navHeight;
      } else {
        nav = navHeight - ulHeight;
      }
      this.nav = nav;
    },
    btn_pos() {
      let f1 = 0;
      let f2 = document.querySelector("#F2").getBoundingClientRect().top;
      let f3 = document.querySelector("#F3").getBoundingClientRect().top;
      let f4 = document.querySelector("#F4").getBoundingClientRect().top;
      let f5 = document.querySelector("#F5").getBoundingClientRect().top;
      let f6 = document.querySelector("#F6").getBoundingClientRect().top;
      let f7 = document.querySelector("#F7").getBoundingClientRect().top;
      let valHeight = [f1, f2, f3, f4, f5, f6, f7];

      //滚动条的高度
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop < 200) {
        this.showTop = false;
      } else {
        this.showTop = true;
      }
      this.scrollTop = scrollTop;
      // 激活对应nav
      valHeight.forEach((v, i) => {
        if (v - this.nav - 10 <= 0) {
          this.navIndex = i + 1;
        }
      });
    },
    toLocation(site) {
      if (site == "F1") {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      } else {
        // document.getElementById(site).scrollIntoView();
        let top = document.getElementById(site).offsetTop;
        document.body.scrollTop = top - this.nav;
        document.documentElement.scrollTop = top - this.nav;
      }
      var a = document.querySelector(".navbar-toggler");
      a.click();
      this.navIndex = Number(site.slice(1, 2));
      this.showDrapDown = false;
    },
    toLocationBack() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    toMap() {
      window.open(
        "http://uri.amap.com/marker?position=120.454193,36.099571&name=青岛元罡科技有限公司&coordinate=gaode&callnative=1"
      );
    },
    //轮播图触摸滚动
    getFlag() {
      this.flagX = event.touches[0].pageX;
      this.flagXM = this.flagX;
    },
    setFlag() {
      this.flagXM = event.touches[0].pageX;
    },
    change() {
      var range = this.flagXM - this.flagX;
      //如果水平滑动距离小于30，就不切换
      if (Math.abs(range) < 30) {
        return false;
      }
      var direction = range < 0 ? "next" : "prev";
      let carousel = function () {};
      carousel(direction);
    },
  },
  watch: {},
};
</script>

<style lang="less">
@import "../assets/css/modal.css";
// 特殊适配
@media screen and (max-width: 992px) {
  // .container,
  // .container-sm {
  //   max-width: 960px;
  // }
}
// 导航栏
.gray {
  box-shadow: 0px 0px 0.38rem 0.19rem rgba(0, 0, 0, 0.16);
  background-color: #f8f9fa !important;
}
.shadow {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.home-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #fff;
  // >1400
  .navbar-toggler:focus {
    box-shadow: 0 0 0 0.1rem;
  }
  .navbar {
    padding-top: 1.31rem;
    padding-bottom: 1.38rem;
  }
  .navbar-collapse {
    flex-grow: 0;
  }
  .nav-logo {
    height: 3.5rem;
    position: relative;
    padding-left: 5.75rem;
    box-sizing: border-box;
    .img {
      width: 3.69rem;
      height: 3.56rem;
      position: absolute;
      top: -0.03rem;
      left: 0;
      background: url("../assets/home/logo1.png") no-repeat;
      background-size: 100% 100%;
    }
    .title {
      height: 3.5rem;
      line-height: 3.5rem;
      font-size: 1.38rem;
      font-weight: 700;
      color: #036eb7;
      letter-spacing: 1px;
    }
  }
  .nav-item {
    margin: 0 0.63rem;
    font-size: 1.38rem;
    cursor: pointer;
    .nav-link {
      color: #3a3a3a;
    }
    .nav-link.active {
      color: #036eb7;
    }
    .nav-link:hover {
      color: #036eb7;
    }
  }
  // <992
  @media screen and (max-width: 992px) {
    .narbarDropDown {
      // margin-top: 1.56rem;
      position: relative;
      bottom: -1.56rem;
      border-top: 0.06rem solid rgba(0, 0, 0, 0.08);
      .nav-item {
        height: 6.88rem;
        line-height: 6.88rem;
        font-size: 1.88rem;
        position: relative;
        border-bottom: 0.03rem solid #f2f2f2;
        .nav-link {
          padding: 0;
        }
        .acrrow {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1rem;
          height: 2rem;
          background: url("../assets/home/qianjin.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .nav-item:last-child {
        border-bottom: none;
      }
    }
    .navbar {
      padding-top: 1.5rem;
      padding-bottom: 1.62rem;
    }
    .nav-logo {
      height: 3.24rem;
      position: relative;
      padding-left: 4.38rem;
      box-sizing: border-box;
      .img {
        width: 3.24rem;
        height: 3.24rem;
        position: absolute;
        top: -0.03rem;
        left: 0;
        background: url("../assets/home/logo2.png") no-repeat;
        background-size: 100% 100%;
      }
      .title {
        height: 3.24rem;
        line-height: 3.24rem;
        font-size: 1.88rem;
        font-weight: 700;
        color: #036eb7;
        letter-spacing: 1px;
      }
    }
    .navbar-toggler-icon {
      width: 2rem;
      height: 2rem;
    }
    .navbar-toggler {
      padding: 0.12rem 0.76rem;
    }
  }
}
// 轮播图
.home-carousel {
  padding-top: 7rem;
  .point {
    width: 0.88rem;
    height: 0.88rem;
    margin-left: 1.31rem;
    margin-right: 1.31rem;
    border-radius: 50%;
    background-color: #abdcfd;
  }
  .point.active {
    border-radius: 0;
    background-color: #fff;
    width: calc(2.56rem - 0.88rem);
    transition: all 0.6s ease;
    position: relative;
  }
  .point.active::after {
    width: 0.88rem;
    height: 0.88rem;
    background-color: #fff;
    position: absolute;
    left: -0.44rem;
    top: 0;
    border-radius: 50%;
    content: "";
  }
  .point.active::before {
    width: 0.88rem;
    height: 0.88rem;
    background-color: #fff;
    position: absolute;
    right: -0.44rem;
    top: 0;
    border-radius: 50%;
    content: "";
  }

  // <576
  @media screen and (max-width: 576px) {
    .point {
      width: 0.8rem;
      height: 0.8rem;
      margin-left: 1.12rem;
      margin-right: 1.12rem;
      border-radius: 50%;
      background: none;
      border: 0.04rem solid #ebf3fc;
      box-sizing: border-box;
    }
    .point.active {
      border-radius: 50%;
      background-color: #fff;
      transition: all 0.6s ease;
      position: relative;
      width: 0.8rem;
      border: none;
      background-color: #fff;
    }
    .point.active::after {
      content: none;
    }
    .point.active::before {
      content: none;
    }
  }
}
// 公司简介
.introduction {
  P {
    margin-bottom: 0;
  }
  text-align: center;
  .title {
    padding-top: 4.94rem;
    margin-bottom: 7.88rem;
    .main {
      font-size: 1.75rem;
      font-weight: 700;
      color: #333;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      display: inline-block;
      position: relative;
      line-height: 1;
    }
    .main::before {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      left: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .main::after {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      right: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .english {
      color: #999;
      font-weight: 700;
      font-size: 0.88rem;
      margin-top: 0.88rem;
      line-height: 1;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    .image {
      height: 28.88rem;
      position: relative;
      width: 50%;
      .bg {
        width: 31.5rem;
        height: 28.88rem;
        border: 3px solid #2f76fa;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
      }
      .img {
        width: 34.44rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .text {
      height: 28.88rem;
      width: 50%;
      position: relative;
      .wrap {
        width: 95%;
        font-size: 1rem;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 2.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  // <768
  @media screen and (max-width: 768px) {
    .title {
      padding-top: 2.63rem;
      margin-bottom: 2rem;
      .main {
        font-size: 1.88rem;
      }
      .english {
        font-size: 0.88rem;
      }
    }
    .content {
      flex-wrap: wrap;
      padding: 0 1rem;
      .image {
        width: 100%;
        position: static;
        height: inherit;
        margin-bottom: 1.94rem;
        .img {
          width: 100%;
          background: url("../assets/home/tu2.png");
          background-size: cover;
          position: static;
          transform: none;
        }
      }
      .text {
        width: 100%;
        font-size: 1.63rem;
        position: static;
        height: inherit;
        .wrap {
          width: 100%;
          font-size: 1.63rem;
          position: static;
          transform: none;
        }
      }
    }
  }
}
// 业务范围
// <768
@media screen and (max-width: 768px) {
  .business {
    background-color: #fff !important;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.business {
  padding-bottom: 8.56rem;
  background-color: #f2f6ff;
  text-align: center;
  margin-top: 5.81rem;
  .title {
    padding-top: 4.69rem;
    margin-bottom: 3.5rem;
    .main {
      font-size: 1.75rem;
      font-weight: 700;
      color: #333;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      display: inline-block;
      position: relative;
      line-height: 1;
    }
    .main::before {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      left: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .main::after {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      right: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .english {
      color: #999;
      font-weight: 700;
      font-size: 0.88rem;
      margin-top: 0.88rem;
      line-height: 1;
    }
  }
  .content {
    padding: 0 5rem;
    box-sizing: border-box;
    .bigItem {
      position: relative;
      height: 29.56rem;
      margin-bottom: 3.56rem;
      .wrap {
        width: 23.75rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 5px 5px 0px rgba(51, 51, 51, 0.1);
        .top {
          height: 6.44rem;
          background-color: #317fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            width: 3.13rem;
            height: 3.13rem;
            margin-top: 0.44rem;
            margin-bottom: 0.69rem;
          }
          .text1 {
            font-size: 1.13rem;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 700;
            text-align: center;
            color: #ffffff;
            line-height: 1;
          }
          .icon1 {
            background: url("../assets/home/icon1.png") no-repeat;
            background-size: 100% 100%;
          }
          .icon2 {
            background: url("../assets/home/icon2.png");
            background-size: 100% 100%;
          }
          .icon3 {
            background: url("../assets/home/icon3.png");
            background-size: 100% 100%;
          }
          .icon4 {
            background: url("../assets/home/icon4.png");
            background-size: 100% 100%;
          }
          .icon5 {
            background: url("../assets/home/icon5.png");
            background-size: 100% 100%;
          }
        }
        .bottom {
          position: relative;
          height: 23.13rem;
          bottom: 0;
          background-color: #fff;
          .package {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1.3rem;
            left: 1.3rem;
            margin: 0;
          }
          .itemWrap {
            position: relative;
            height: 2.63rem;
            margin-bottom: 0.94rem;
            .item {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 10rem;
              background: #dceafc;
              border-radius: 0.38rem;
              text-align: center;
              font-size: 1rem;
              line-height: 2.63rem;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              color: #2a83fd;
            }
          }
        }
      }
      .lastWrap {
        background-color: #317fff;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 3rem;
        box-sizing: border-box;
        box-shadow: 0px 5px 5px 0px rgba(51, 51, 51, 0.1);
        .lastIcon {
          margin: 0 auto;
          width: 3.13rem;
          height: 3.13rem;
          background: url("../assets/home/icon6.png") no-repeat;
          background-size: 100% 100%;
          margin-bottom: 2.94rem;
        }
        .lastText {
          line-height: 1;
          font-size: 1.13rem;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
  // <1400
  @media screen and (max-width: 1400px) {
    .content .bigItem .wrap {
      width: 30rem;
      .item {
        width: 13rem;
      }
    }
  }
  // <992
  @media screen and (max-width: 992px) {
    .content {
      padding: 0 1rem;
    }
  }
  // <768
  @media screen and (max-width: 768px) {
    .title {
      padding-top: 4.69rem;
      margin-bottom: 2.88rem;
      .main {
        font-size: 1.88rem;
      }
      .english {
        font-size: 0.88rem;
      }
    }
    .content {
      .bigItem2 {
        height: 46.94rem;
        .bottom {
          height: 39.56rem !important;
        }
      }
      .bigItem5 {
        height: 22.56rem;
        .bottom {
          height: 15.25rem !important;
        }
      }
      .bigItem6 {
        height: 21.63rem;
        margin-bottom: 0;
      }
      .bigItem {
        .wrap {
          width: 100%;
          .top {
            height: 7.38rem;
            .icon {
            }
            .text1 {
              font-size: 1.88rem;
            }
            .icon1 {
              background: url("../assets/home/icon11.png") no-repeat;
              background-size: 100% 100%;
            }
            .icon2 {
              background: url("../assets/home/icon22.png");
              background-size: 100% 100%;
            }
            .icon3 {
              background: url("../assets/home/icon33.png");
              background-size: 100% 100%;
            }
            .icon4 {
              background: url("../assets/home/icon44.png");
              background-size: 100% 100%;
            }
            .icon5 {
              background: url("../assets/home/icon55.png");
              background-size: 100% 100%;
            }
          }
          .bottom {
            height: 22.13rem;
            .package {
            }
            .itemWrap {
              height: 4.75rem;
              .item {
                width: 18.13rem;
                height: 4.75rem;
                line-height: 4.75rem;
                font-size: 1.75rem;
              }
            }
          }
        }
        .lastWrap {
          padding-bottom: 0;
          .lastIcon {
          }
          .lastText {
          }
        }
      }
    }
  }
  // <320
  @media screen and (max-width: 320px) {
    .item {
      width: 16.13rem !important;
    }
  }
}
// 服务流程
// <768
@media screen and (max-width: 768px) {
  .flow {
    padding-bottom: 0 !important;
  }
}
.flow {
  text-align: center;
  padding-bottom: 7.13rem;
  .title {
    padding-top: 4.69rem;
    margin-bottom: 9.06rem;
    .main {
      font-size: 1.75rem;
      font-weight: 700;
      color: #333;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      display: inline-block;
      position: relative;
      line-height: 1;
    }
    .main::before {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      left: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .main::after {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      right: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .english {
      color: #999;
      font-weight: 700;
      font-size: 0.88rem;
      margin-top: 0.88rem;
      line-height: 1;
    }
  }
  .content {
    padding: 0 9.94rem;
    box-sizing: border-box;
  }
  // <768
  @media screen and (max-width: 768px) {
    .title {
      padding-top: 4.19rem;
      margin-bottom: 4.38rem;
      .main {
        font-size: 1.88rem;
      }
      .english {
        font-size: 0.88rem;
      }
    }
    .content {
      padding: 0 1rem;
    }
  }
}
// 公司业绩
// <768
@media screen and (max-width: 768px) {
  .performance {
    background-color: #fff !important;
    padding-top: 5.63rem !important;
    padding-bottom: 0 !important;
  }
}
.performance {
  background-color: #f2f6ff;
  text-align: center;
  padding-bottom: 9.25rem;
  .title {
    padding-top: 4.69rem;
    margin-bottom: 9.38rem;
    .main {
      font-size: 1.75rem;
      font-weight: 700;
      color: #333;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      display: inline-block;
      position: relative;
      line-height: 1;
    }
    .main::before {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      left: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .main::after {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      right: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .english {
      color: #999;
      font-weight: 700;
      font-size: 0.88rem;
      margin-top: 0.88rem;
      line-height: 1;
    }
  }
  .content {
    padding: 0 9.94rem;
    box-sizing: border-box;
  }
  // <768
  @media screen and (max-width: 768px) {
    .title {
      padding-top: 0;
      margin-bottom: 3.31rem;
      .main {
        font-size: 1.88rem;
      }
      .english {
        font-size: 0.88rem;
      }
    }
    .content {
      padding: 0 1rem;
    }
  }
}
// 应用案例
// <592
@media screen and (max-width: 592px) {
  .case {
    padding-bottom: 0 !important;
  }
}
.case {
  text-align: center;
  padding-bottom: 7.13rem;
  .title {
    padding-top: 6.44rem;
    margin-bottom: 10rem;
    .main {
      font-size: 1.75rem;
      font-weight: 700;
      color: #333;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      display: inline-block;
      position: relative;
      line-height: 1;
    }
    .main::before {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      left: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .main::after {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      right: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .english {
      color: #999;
      font-weight: 700;
      font-size: 0.88rem;
      margin-top: 0.88rem;
      line-height: 1;
    }
  }
  .contentPc {
    padding: 0 9.94rem;
    box-sizing: border-box;
    .bg {
      position: relative;
      width: 32.69rem;
      height: 27.63rem;
      background: url("../assets/home/case.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      .item {
        width: 16.13rem;
        height: 3.75rem;
        line-height: 1;
        position: absolute;
        cursor: pointer;
      }
      .item1 {
        left: -15rem;
        top: -1.8rem;
        background: url("../assets/home/case1.png") no-repeat;
        background-size: 100% 100%;
      }
      .item2 {
        left: -15rem;
        top: 3.7rem;
        background: url("../assets/home/case2.png") no-repeat;
        background-size: 100% 100%;
      }
      .item3 {
        left: -15rem;
        top: 8.7rem;
        background: url("../assets/home/case3.png") no-repeat;
        background-size: 100% 100%;
      }
      .item4 {
        left: -15rem;
        top: 14rem;
        background: url("../assets/home/case4.png") no-repeat;
        background-size: 100% 100%;
      }
      .item5 {
        left: -15rem;
        top: 19.5rem;
        background: url("../assets/home/case5.png") no-repeat;
        background-size: 100% 100%;
      }
      .item6 {
        left: -15rem;
        top: 25.4rem;
        background: url("../assets/home/case6.png") no-repeat;
        background-size: 100% 100%;
      }
      .item7 {
        left: 32rem;
        top: -1.8rem;
        background: url("../assets/home/case7.png") no-repeat;
        background-size: 100% 100%;
      }
      .item8 {
        left: 32rem;
        top: 3.7rem;
        background: url("../assets/home/case8.png") no-repeat;
        background-size: 100% 100%;
      }
      .item9 {
        left: 32rem;
        top: 8.7rem;
        background: url("../assets/home/case9.png") no-repeat;
        background-size: 100% 100%;
      }
      .item10 {
        left: 32rem;
        top: 14rem;
        background: url("../assets/home/case10.png") no-repeat;
        background-size: 100% 100%;
      }
      .item11 {
        left: 32rem;
        top: 19.6rem;
        background: url("../assets/home/case11.png") no-repeat;
        background-size: 100% 100%;
      }
      .item12 {
        left: 32rem;
        top: 25.4rem;
        background: url("../assets/home/case12.png") no-repeat;
        background-size: 100% 100%;
      }
      .centre {
        position: absolute;
        top: 49%;
        left: 51%;
        transform: translate(-50%, -50%);
        .icon {
          width: 4.63rem;
          height: 3.25rem;
          background: url("../assets/home/LOGO-01.png") no-repeat;
          background-size: 100% 100%;
          margin: 0 auto;
          margin-bottom: 1.44rem;
        }
        .text1 {
          font-size: 1.25rem;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: justifyLeft;
          color: #ffffff;
          line-height: 1;
          text-align: center;
        }
      }
      .bottom {
        position: absolute;
        top: 26.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 24rem;
        .text1 {
          font-size: 1rem;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: justifyLeft;
          color: #333333;
          margin-bottom: 1.19rem;
        }
        .english {
          color: #999999;
        }
      }
    }
  }
  .contentMobile {
    padding: 0 1rem;
    box-sizing: border-box;
    .wrap {
      box-shadow: 0px 5px 5px 0px rgba(51, 51, 51, 0.1);
    }

    .top {
      height: 7.38rem;
      background: #317fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon {
        width: 4.63rem;
        height: 3.25rem;
        background: url("../assets/home/LOGO-01-2.png") no-repeat;
        background-size: 100% 100%;
        margin-top: 0.44rem;
        margin-bottom: 0.75rem;
      }
      .text1 {
        font-size: 1.88rem;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
        line-height: 1;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 2.44rem 0.8rem 1.06rem;
      box-sizing: border-box;
      .item {
        width: 49%;
        height: 4.38rem;
        background-color: #dceafc;
        color: #317fff;
        border-radius: 999em;
        font-size: 1.38rem;
        line-height: 4.38rem;
        text-align: center;
        padding-right: 10px;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 1.25rem;
        cursor: pointer;
        .acrrow {
          width: 1.69rem;
          height: 1.75rem;
          background: url("../assets/home/acrrowRight.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 0.6rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  // <768
  @media screen and (max-width: 768px) {
    .title {
      padding-top: 4.88rem;
      margin-bottom: 4.38rem;
      .main {
        font-size: 1.88rem;
      }
      .english {
        font-size: 0.88rem;
      }
    }
    .contentPc {
      padding: 0 1rem;
    }
  }
}
// 联系我们
// <768
@media screen and (max-width: 768px) {
  .relation {
    margin-top: 3.81rem !important;
    padding-bottom: 2.69rem !important;
  }
}
.relation {
  text-align: center;
  padding-bottom: 10.75rem;
  .title {
    padding-top: 9.63rem;
    margin-bottom: 9.38rem;
    .main {
      font-size: 1.75rem;
      font-weight: 700;
      color: #333;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      display: inline-block;
      position: relative;
      line-height: 1;
    }
    .main::before {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      left: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .main::after {
      content: "";
      width: 7.13rem;
      height: 0.13rem;
      background-color: #e0e0e0;
      position: absolute;
      right: -9.38rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .english {
      color: #999;
      font-weight: 700;
      font-size: 0.88rem;
      margin-top: 0.88rem;
      line-height: 1;
    }
  }
  .content {
    padding: 0 9.94rem;
    box-sizing: border-box;
    cursor: pointer;
  }
  // <768
  @media screen and (max-width: 768px) {
    .title {
      padding-top: 0;
      margin-bottom: 3.31rem;
      .main {
        font-size: 1.88rem;
      }
      .english {
        font-size: 0.88rem;
      }
    }
    .content {
      padding: 0 1rem;
    }
  }
}
// 底部信息栏
.home-bottom {
  box-sizing: border-box;
  background-color: #252f35;
  .msg {
    padding: 0 9.94rem;
    padding-top: 3.94rem;
    height: 17.06rem;
    display: flex;

    .block {
      padding: 1rem 2rem 0 1.88rem;
      border-right: 0.06rem solid #aaaaaa;
      height: 6.31rem;
      width: 35%;
      box-sizing: border-box;
      margin-top: 1.19rem;
      .item {
        font-size: 0.88rem;
        color: #fff;
        display: flex;
        line-height: 1.88rem;
        .icon {
          width: 1.13rem;
          height: 1.13rem;
          margin-right: 0.94rem;
          margin-top: 0.4rem;
        }
        .icon1 {
          background: url("../assets/home/gongsi.png") no-repeat;
          background-size: 100% 100%;
        }
        .icon2 {
          background: url("../assets/home/youxiang.png") no-repeat;
          background-size: 100% 100%;
        }
        .icon3 {
          background: url("../assets/home/telephone.png") no-repeat;
          background-size: 100% 100%;
        }
        .icon4 {
          background: url("../assets/home/dizhi.png") no-repeat;
          background-size: 100% 100%;
        }
        .text1 {
          width: 15.81rem;
        }
      }
    }
    .QR {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      .text1 {
        font-size: 0.63rem;
        color: #fff;
        margin-bottom: 0.63rem;
      }
      img {
        width: 7.44rem;
        height: 7.44rem;
      }
    }
    .moblieBlock {
      font-size: 1rem;
      color: #fff;
    }
  }
}
@media screen and (max-width: 768px) {
  .home-bottom .msg {
    padding: 0 1rem;
    padding-top: 3.94rem;
    box-sizing: border-box;
  }
}
.copyright {
  line-height: 3.13rem;
  background-color: #121d25;
  text-align: center;
  font-size: 0.75rem;
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .copyright {
    line-height: 2rem;
    padding: 1.06rem 1rem;
  }
}
.backOrigin {
  width: 5.31rem;
  height: 5.31rem;
  background: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 1rem 0 rgba(62, 97, 121, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 6.25rem;
  bottom: 8rem;
  cursor: pointer;
  .icon {
    width: 1.648rem;
    height: 0.848rem;
    background: url("../assets/home/sanjiao.png");
    background-size: 100% 100%;
    margin-bottom: 0.8rem;
  }
  .text1 {
    line-height: 1;
    font-size: 1.25rem;
    color: #6f6f6f;
    text-align: center;
  }
}
.backOriginM {
  width: 6.19rem;
  height: 6.81rem;
  position: fixed;
  right: 0;
  bottom: 8.81rem;
  background: url("../assets/home/back.png");
  background-size: 100% 100%;
  cursor: pointer;
}
</style>
