<template>
  <div>
    <div class="modal-dialog-centered">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header modalTitle">
            <h3 class="modal-title" id="exampleModalLabel">
              <slot name="up"></slot>
            </h3>
            <img
              data-bs-dismiss="modal"
              aria-label="Close"
              src="../../assets/modal/guanbi.png"
              alt=""
            />
          </div>
          <div class="modal-body">
            <slot name="down"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {},
  mounted() {
    var resizeEvt = "orientationchange" in window ? "orientationchange" : "resize";
    var setFont = function () {
      // 获取设备屏幕的宽度
      var html = document.documentElement;
      var width = html.clientWidth; // 获取html的宽度
      // var height = document.documentElement.clientHeight
      // 判断一下，width值是否小于1024；是否大于1920
      if (width < 1024) {
        width = 1024;
      }
      if (width > 1920) {
        width = 1920;
      }
      // 计算html的fontSize值
      var fontSize = width / 120 + "px";
      // 设置html元素的font-size
      html.style.fontSize = fontSize;
    };
    setFont(); //自调用先生效
    // 浏览器窗口大小改变的时候，重新设置html的fontSize
    window.onresize = function () {
      setFont();
    };
  },
});
</script>

<style lang="less" scoped>
.modal-content {
  min-height: 50.25rem;
  .modalTitle {
    background: #1957f6;
    height: 4.75rem;
    img {
      width: 2.875rem;
      height: 2.875rem;
      cursor: pointer;
      margin-right: 1.4375rem;
    }
    h3 {
      font-size: 1.875rem;
      font-weight: 700;
      margin: 0 auto;
      color: #ffffff;
    }
  }
  .modal-body {
    padding: 0 4.7rem;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1200px;
  }
}
@media (max-width: 768px) {
  .modal-content .modal-body {
    padding: 0 1.875rem;
  }
}
@media (max-width: 375px) {
  .modal-dialog {
    margin: 1rem;
  }
}
</style>

